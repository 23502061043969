import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import css from "@styled-system/css"
import { Link } from "gatsby"
import { useAuth } from "contexts/auth"

import {
  Alert,
  Box,
  Button,
  Heading,
  Input,
  SEO,
  Spinner,
  Text,
  Wrapper,
} from "components"

import ErrorDisplay from "../_errorDisplay"

function ResetPassword() {
  // Retrieve the forgot password function from the auth context
  const { updateUser } = useAuth()

  // Managing form state
  const [formState, setFormState] = useState("")

  // Hold variables from inputs
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [repeatPasswordError, setRepeatPasswordError] = useState("")
  const [failedAttempt, setFailedAttempt] = useState(false)

  // Check if error flag sent from home, and set form state accordingly
  useEffect(() => {
    if (window.location.search.indexOf("?error=true") !== -1) {
      setFormState("resetError")
    }
  }, [])

  const forgotSubmit = event => {
    // Block default HTML5 submit
    event.preventDefault()

    // Run form validation
    let error = false
    // Password Check
    setPasswordError("")
    if (password.length < 6) {
      error = true
      if (password.length === 0) {
        setPasswordError("Please enter a password in this field.")
      } else {
        setPasswordError("Password must be six characters or longer.")
      }
    }

    // Repeat Password Check
    setRepeatPasswordError("")
    if (repeatPassword !== password) {
      error = true
      setRepeatPasswordError(
        "Passwords do not match. Please check your spelling and try again."
      )
    }

    if (!error) {
      // Submit to server
      setFormState("processing")
      updateUser(
        { password: password },
        () => {
          setFormState("success")
        },
        () => {
          setFormState("")
          setFailedAttempt(true)
        }
      )
    }
  }

  return (
    <>
      <SEO title="Reset Password | Teine" description="Reset your password" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box
        width={["100%", "calc(100% - 64px)"]}
        mx="auto"
        minHeight="50vh"
        mt={[128, 224]}
        bg="wash"
      >
        <Wrapper
          py={["layout.7", "layout.8"]}
          maxWidth={600}
          mx="auto"
          css={css({
            transition: "opacity 200ms ease-out",
            "> *": {
              py: 16,
            },
          })}
          style={
            formState === "processing"
              ? {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                }
              : undefined
          }
        >
          <Heading
            children={"Reset password"}
            mb={["layout.4", "layout.6"]}
            size={800}
            textAlign="center"
          />

          {/* Processing State */}
          {(formState === "" ||
            formState === "processing" ||
            formState === "failure") && (
            <Box>
              {failedAttempt && (
                <Text mb="layout.2">
                  <Alert
                    heading="Error submitting reset email"
                    text="Your reset attempt returned an error. Please try again. If you continue having submission issues, please contact us."
                  />
                </Text>
              )}
              <Text mb="layout.2">
                To change your password, fill out the form below.
              </Text>
              <Box as="form" onSubmit={forgotSubmit} mt="layout.4" noValidate>
                <Box
                  css={css({
                    "> *": {
                      py: 16,
                    },
                  })}
                >
                  {/* Password Entry Field */}
                  <Box>
                    <Input
                      id="reset-password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                      required="true"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    {/* Password Error Message */}
                    {passwordError && <ErrorDisplay message={passwordError} />}
                  </Box>

                  <Box>
                    {/* Confirm Password Field */}
                    <Input
                      id="reset-password-repeat"
                      label="Repeat Password"
                      type="password"
                      placeholder="Repeat password"
                      required="true"
                      value={repeatPassword}
                      onChange={e => setRepeatPassword(e.target.value)}
                    />
                    {/* Confirm Password Error */}
                    {repeatPasswordError && (
                      <ErrorDisplay message={repeatPasswordError} />
                    )}
                  </Box>
                </Box>
                <Box mt={["layout.2", "layout.4"]}>
                  <Button
                    children="Reset password"
                    type="submit"
                    opacity={formState === "processing" ? "0.5" : "1"}
                    css={css({
                      pointerEvents: formState ? "none" : "auto",
                    })}
                  />
                </Box>
              </Box>
              {/* Processing Message */}
              <Box
                position="absolute"
                top={0}
                left={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                opacity={0}
                css={{
                  transition: "opacity 200ms ease-out",
                  pointerEvents: "none",
                  userSelect: "none",
                }}
                style={
                  formState === "processing"
                    ? {
                        opacity: 1,
                      }
                    : undefined
                }
              >
                <Spinner />
              </Box>
            </Box>
          )}
          {/* Successful Submission */}
          {formState === "success" && (
            <>
              <Text
                children="Your password has been reset. You can now continue to the portal."
                mb="layout.2"
              />
              <Link to="/shareholders/portal">
                <Button children={"View portal"} />
              </Link>
            </>
          )}
          {/* Error state */}
          {formState === "resetError" && (
            <>
              <Text
                children="Your reset link has expired. Please request another link and try again."
                mb="layout.2"
              />
              <Link to="/shareholders/forgot">
                <Button children={"Request another link"} />
              </Link>
            </>
          )}
        </Wrapper>
      </Box>
    </>
  )
}

export default ResetPassword
